import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { MenubarModule } from 'primeng/menubar';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { LoginService } from '../../services/login.service';
import { GeneralService } from '../../services/general.service';
import { AvatarModule } from 'primeng/avatar';
import { Subscription } from 'rxjs';
import { CustomMenuItem } from '../../models/menu-bar';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [MenubarModule, CommonModule, ProgressSpinnerModule, AvatarModule],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
})
export class NavbarComponent {
  items: CustomMenuItem[] | undefined;
  itemsRights: CustomMenuItem[] | undefined;
  itemsSession: CustomMenuItem[] | undefined;
  activeSection: string | undefined;
  isMenuOpen: boolean = false;
  isLoading: boolean = false;
  user: any;
  tokenStorage: any;
  dataUser: any;
  previousUserData: any;
  isDropdownOpen: boolean = false;
  isUserMenuOpen: boolean = false;
  isMobile: boolean = false;
  userSubscription!: Subscription;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private generalService: GeneralService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.isMobile = window.innerWidth <= 960;
    console.log('isMobile', this.isMobile);
    this.initializeMenuItems(); // Inicializa los menús sin sesión
    this.getTokenStorage(); // Recupera el token y, si existe, actualiza el estado del usuario

    this.loginService.userSubject.subscribe((res) => {
      this.user = res;
      this.tokenStorage = this.generalService.getKey('token');
      if (this.isUserLoggedIn()) {
        this.isLoading = true;
        this.updateMenuItems();

        if (!this.user) {
          this.generalService.set('user', 'false');
          this.getDataUser();
          console.log('El usuario no está disponible, actualizando datos...');
        } else {
          this.generalService.set('user', 'true');
          this.getDataUser();
          this.updateMenuItems();
        }
      } else {
        this.updateMenuItems();
      }
    });

    const storedOption = this.generalService.getKey('selectedOption');
    this.activeSection = storedOption ? storedOption : 'Disponibles';
    this.updateMenuItems();
  }

  login() {
    this.onLoginClick();
  }

  reserve() {
    this.onMenuItemClick('Reservas');
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  toggleDropdown() {
    this.isUserMenuOpen = !this.isUserMenuOpen;
  }

  private isUserLoggedIn(): boolean {
    const token = this.generalService.getKey('token');
    return token !== undefined && token !== null;
  }

  private isEqual(obj1: any, obj2: any): boolean {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  initializeMenuItems() {
    this.items = [
      {
        label: 'Alojamiento',
        isOpen: false,
        items: [
          {
            label: 'Departamentos',
            command: () => this.onMenuItemClick('Departamentos'),
          },
          {
            label: 'Habitaciones',
            command: () => this.onMenuItemClick('Habitaciones'),
          },
          {
            label: 'Suits',
            command: () => this.onMenuItemClick('Suits'),
          },
        ],
      },
      {
        label: 'Restaurante',
        command: () => this.onMenuItemClick('Restaurantes'),
      },
      {
        label: 'Experiencia',
        isOpen: false,
        items: [
          {
            label: 'Fullday',
            command: () => this.onMenuItemClick('Fullday'),
          },
        ],
      },
      {
        label: 'Eventos',
        command: () => this.onMenuItemClick('Eventos'),
      },
      {
        label: 'Nosotros',
        command: () => this.onMenuItemClick('Nosotros'),
      },
      {
        label: 'Contacto',
        command: () => this.onMenuItemClick('Contacto'),
      },
    ];
    (this.itemsRights = [
      {
        label: 'Iniciar sesión',
        command: (event) => this.onLoginClick(),
      },
      {
        label: 'Reservar',
        command: (event) => this.onMenuItemClick('Reservas'),
      },
    ]),
      (this.itemsSession = [
        {
          label: 'Inicio',
          command: () => this.onMenuItemClick('Inicio'),
        },
        {
          label: 'Mis datos',
          command: () => this.onMenuItemClick('Perfil'),
        },
        {
          label: 'Reservas',
          isOpen: false,
          items: [
            {
              label: 'Disponibles',
              command: () => this.onMenuItemClick('Disponibles'),
            },
            {
              label: 'Reservados',
              command: () => this.onMenuItemClick('Reservados'),
            },
            {
              label: 'Históricos',
              command: () => this.onMenuItemClick('Históricos'),
            },
          ],
        },
        {
          label: 'Beneficios',
        },
        {
          label: 'Mis puntos',
        },
        {
          label: 'Cerrar Sesión',
          command: () => this.logout(),
        },
      ]);
  }

  getTokenStorage() {
    if (typeof window !== 'undefined' && window.sessionStorage) {
      this.tokenStorage = sessionStorage.getItem('token');
      if (this.tokenStorage) {
        this.getDataUser(); // Llama a getDataUser inmediatamente para actualizar el estado de usuario
      } else {
        this.user = null; // Se asegura de que user se establezca en null si no hay token
      }
    } else {
      console.warn('sessionStorage is not available');
      this.tokenStorage = null;
      this.user = null; // Establece user como null si sessionStorage no está disponible
    }
    this.updateMenuItems(); // Actualiza el menú después de manejar el token y usuario
  }

  toggleSubmenu(item: CustomMenuItem) {
    const menuList = this.items?.includes(item)
      ? this.items
      : this.itemsSession;
    menuList?.forEach((menuItem) => {
      if (menuItem !== item) {
        menuItem.isOpen = false;
      }
    });

    item.isOpen = !item.isOpen;
    this.cdr.detectChanges();
  }

  updateMenuItems() {
    console.log('user', this.user);
    console.log('tokenStorage', this.tokenStorage);
    if (this.user || this.tokenStorage) {
      this.items = this.itemsSession;
    } else if (this.user == null && this.tokenStorage == null) {
      this.initializeMenuItems();
    }
    this.cdr.detectChanges();
  }

  onLoginClick() {
    this.isLoading = true;
    this.router.navigate(['/login']).then(() => {
      this.isLoading = false;
      setTimeout(() => {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
      }, 100);
    });
  }

  logout() {
    this.isUserMenuOpen = false;
    this.isLoading = true;
    this.loginService.userSubject.next(null);
    this.user = null;
    this.tokenStorage = null;
    this.clearStorage();

    this.router.navigate(['/']).then(() => {
      this.isLoading = false;

      window.location.reload();
    });
  }

  clearStorage() {
    if (typeof window !== 'undefined' && window.localStorage) {
      localStorage.clear();
      sessionStorage.clear();
      localStorage.removeItem('selectedOption');
    }
  }

  onLogoClick() {
    this.isLoading = true;
    const isUserLoggedIn = this.user && this.user.userClientId;
  
    let navigationPath = '';
  
    if (isUserLoggedIn) {
      navigationPath = '/welcome';
    } else {
      navigationPath = '/';
    }

    this.router.navigate([navigationPath]).then(() => {
      this.isLoading = false;
      setTimeout(() => {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
      }, 100);
    });
  }

  onMenuItemClick(section: string) {
    this.isLoading = true; 
    this.activeSection = section;

    this.items?.forEach(item => item.isOpen = false);
    this.itemsSession?.forEach(item => item.isOpen = false);
  
    let navigationPath = '';
    if (section === 'Reservas') {
      localStorage.setItem('selectedOption', 'Disponibles');
      this.generalService.updateSelectedOption('Disponibles');
      navigationPath = '/bookings/disponibles';
    } else if (section === 'Perfil') {
      console.log('this user en perfil', this.user);
  
      if (this.user && this.user.userClientId) {
        this.router.navigate(['/profile', this.user.userClientId]).then(() => {
          this.isLoading = false;
          setTimeout(() => {
            document.documentElement.scrollTop = 0;
            document.body.scrollTop = 0;
          }, 100);
        });
        return;
      } else {
        console.warn('El usuario o userClientId no está disponible.');
      }
    } else {
      // Para las demás opciones, almacenar y navegar normalmente
      localStorage.setItem('selectedOption', section);
      this.generalService.updateSelectedOption(section);
  
      if (section === 'Disponibles') {
        navigationPath = '/bookings/disponibles';
      } else if (section === 'Reservados') {
        navigationPath = '/bookings/reservados';
      } else if (section === 'Históricos') {
        navigationPath = '/bookings/históricos';
      } else if (section === 'Inicio') {
        navigationPath = '/welcome';
      } else if (section === 'Departamentos') {
        navigationPath = '/accommodation/departments';
      } else if (section === 'Habitaciones') {
        navigationPath = '/accommodation/rooms';
      } else if (section === 'Suits') {
        navigationPath = '/accommodation/suits';
      } else if (section === 'Restaurantes') {
        navigationPath = '/restaurant';
      } else if (section === 'Fullday') {
        navigationPath = '/experience';
      } else if (section === 'Eventos') {
        navigationPath = '/event';
      } else if (section === 'Nosotros') {
        navigationPath = '/about';
      } else if (section === 'Contacto') {
        navigationPath = '/contact';
      }
  
    }
    this.router.navigate([navigationPath]).then(() => {
      this.isLoading = false;
      setTimeout(() => {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
      }, 100);
    });
  }

  getDataUser() {
    if (!this.tokenStorage) {
      this.dataUser = null;
      this.user = null; // Asegúrate de que user sea null si no hay token
      localStorage.removeItem('userData');
      this.updateMenuItems();
      this.cdr.detectChanges();
      return;
    }

    this.generalService.getApiUsers('find/my-data').subscribe(
      (response) => {
        this.dataUser = response;
        this.user = response;
        this.isLoading = false;
        localStorage.setItem('userData', JSON.stringify(this.user));
        this.updateMenuItems();
        this.cdr.detectChanges();
      },
      (error) => {
        console.error('Error fetching data user:', error);
        this.user = null;
        this.isLoading = false;
        localStorage.removeItem('userData');
        this.updateMenuItems(); // Asegúrate de que el menú refleje el cierre de sesión
        this.cdr.detectChanges();
      }
    );
  }
}